<template>
  <v-container class="px-0" data-cy="WelcomeCitiesCards">
    <h2 class="welcome-page-title size20-weight400">{{ title }}</h2>
    <carousel
      :scrollPerPage="$vuetify.breakpoint.lgAndUp"
      :perPage="carouselSlideSizeForWebOrQueryItem"
      :navigationEnabled="false"
      :paginationEnabled="false"
      :touchDrag="true"
      :mouseDrag="true"
    >
      <slide
        v-for="(item, index) in items"
        :key="index"
        :data-cy="`category-item`"
        class="welcome-page-slide pa-1"
      >
        <div
          @click="pushExploreDestinationsAnalytics('explore_city', item.title)"
        >
          <router-link
            class="welcome-page-card"
            :to="{ name: 'discoveryWithCity', query: cityQuery(item) }"
          >
            <div class="welcome-page-card-image-wrapper">
              <img
                :src="item.preview"
                :alt="item.title"
                class="welcome-page-card-image"
              />
            </div>
            <h3 class="welcome-page-card-title size16-weight600">
              {{ item.title }}
            </h3>
            <div class="welcome-page-card-description size12-weight400">
              {{ item.description }}
              {{ $t('welcome.FindExperiencesDescription') }}
            </div>
          </router-link>
        </div>
      </slide>
    </carousel>
  </v-container>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
export default {
  props: {
    displayPage: String,
    title: String,
    items: {
      type: [Array, Object],
      default: () => [],
    },
    maxHeight: {
      type: Number,
      default: 220,
    },
  },
  components: {
    Carousel,
    Slide,
  },
  mixins: [GoogleTagManagerMixins],
  computed: {
    isWelcomePage() {
      return this.$route?.name === 'welcome';
    },
    isItemCatalogType() {
      return this.catalogType === 'Item';
    },
    carouselSlideSizeForWebOrQueryItem() {
      let pageSize = 6;
      if (this.$vuetify.breakpoint.mdAndDown) {
        pageSize = 3.5;
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 2.1;
      }
      return this.items.length >= pageSize ||
        Object.keys(this.items).length >= pageSize
        ? pageSize
        : 3;
    },
  },
  methods: {
    cityQuery(city) {
      return { ...city.query, city: city.title };
    },
  },
};
</script>
<style scoped lang="scss">
.welcome-page {
  &-title {
    color: var(--v-grey8-base);
    margin-bottom: 26px;
  }
  &-card {
    width: 168px;
    cursor: pointer;
    text-decoration: none;
    &-title {
      color: var(--v-grey8-base);
      line-height: 19px;
      margin-top: 16px;
      margin-bottom: 6px;
    }
    &-description {
      color: var(--v-grey8-base);
    }
    &-image {
      width: 100%;
      object-fit: cover;
      object-position: center;
      &-wrapper {
        display: flex;
        height: 120px;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }
  &-slide {
    white-space: initial;
    @media (max-width: map-get($grid-breakpoints, md)) {
      box-sizing: content-box;
      &:first-child {
        padding-left: 24px !important;
      }
    }
  }
}
.link {
  text-decoration: none;
}
::v-deep {
  & .VueCarousel-navigation-next {
    transform: translateY(-100%) translateX(-30%) !important;
  }
  & .VueCarousel-navigation-prev {
    transform: translateY(-100%) translateX(30%) !important;
  }
  & .VueCarousel-inner {
    @media (max-width: map-get($grid-breakpoints, md)) {
      margin-right: 100px;
    }
  }
  & .VueCarousel-wrapper {
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: calc(100% + 24px);
    }
  }
  & .VueCarousel {
    @media (max-width: map-get($grid-breakpoints, md)) {
      margin-right: -12px;
      margin-left: -24px;
    }
  }
  & #circle1:hover {
    cursor: pointer;
    opacity: 1.5;
  }
}
</style>
