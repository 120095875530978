<template>
  <v-container class="px-0" data-cy="WelcomeExperiencesCards">
    <div class="welcome-page-title-wrapper">
      <h2 class="welcome-page-title size20-weight400">{{ title }}</h2>
      <router-link
        @click.native="pushSeeMoreToAnalytics(city, title)"
        class="welcome-page-link"
        :to="{ name: 'discoveryWithCity', query: query }"
      >
        {{ $t('welcome.call_to_action_text') }}
      </router-link>
    </div>
    <carousel
      class="mx-xs-0 mx-sm-0 mx-md-0"
      :scrollPerPage="$vuetify.breakpoint.lgAndUp"
      :perPage="carouselSlideSizeForWebOrQueryItem"
      :navigationEnabled="false"
      :paginationEnabled="false"
      :touchDrag="true"
      :mouseDrag="true"
    >
      <slide
        v-for="(item, index) in items"
        :key="index"
        :data-cy="`category-item`"
        class="welcome-page-slide px-1 py-1"
      >
        <div @click="pushWelcomeItemClickAnalytics(item, city)">
          <router-link
            class="welcome-page-card"
            :to="{
              name: 'booking-details',
              params: { id: item.id },
              query: { welcome: true },
            }"
          >
            <div class="welcome-page-card-image-wrapper">
              <img
                :src="item.preview || item.thumbnail"
                :alt="item.title"
                class="welcome-page-card-image"
              />
            </div>
            <h3 class="welcome-page-card-title size16-weight600">
              {{ item.title }}
            </h3>
            <div class="welcome-page-card-description size12-weight400">
              <template v-if="item.description">
                {{ $t('common.from') }}
                {{ item.description }}{{ $t('common.per_person') }}
              </template>
              <template v-else>
                {{ $t('common.from') }}
                {{
                  item.priceStartingAt.value
                    | currency(item.priceStartingAt.baseCurrency)
                }}{{ $t('common.per_person') }}
              </template>
            </div>
          </router-link>
        </div>
      </slide>
    </carousel>
  </v-container>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'WelcomeExperienceCards',
  props: {
    displayPage: String,
    title: String,
    items: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: Number,
      default: 220,
    },
    query: {
      type: Object,
      default: () => {
        return {};
      },
    },
    city: {
      type: String,
      default: '',
    },
    perPage: {
      type: Number,
      default: null,
    },
  },
  components: {
    Carousel,
    Slide,
  },
  mixins: [GoogleTagManagerMixins, HotJarMixins],
  computed: {
    isItemCatalogType() {
      return this.catalogType === 'Item';
    },
    carouselSlideSizeForWebOrQueryItem() {
      if (this.perPage) return this.perPage;
      let pageSize = 6;
      if (this.$vuetify.breakpoint.mdAndDown) {
        pageSize = 3.5;
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 2.1;
      }
      return this.items.length >= pageSize ? pageSize : 3;
    },
  },
  methods: {
    pushSeeMoreToAnalytics(city, title) {
      this.pushSeeMoreAnalytics(city, title);
      this.pushHotJarAnalytics('welcome_see_more');
    },
  },
};
</script>
<style scoped lang="scss">
.welcome-page {
  &-title {
    color: var(--v-grey8-base);

    &-wrapper {
      margin-bottom: 32px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      @media (min-width: map-get($grid-breakpoints, sm)) {
        justify-content: space-between;
        flex-direction: row;
      }
    }
  }

  &-link {
    color: var(--v-secondary-base);
    @include font-size(16, 150);
    text-decoration: none;
  }

  &-card {
    cursor: pointer;
    text-decoration: none;
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
    }

    &-title {
      color: var(--v-grey8-base);
      line-height: 19px;
      margin-top: 16px;
      margin-bottom: 6px;
    }

    &-description {
      color: var(--v-grey8-base);
    }

    &-image {
      width: 100%;
      object-fit: cover;
      object-position: center;

      &-wrapper {
        display: flex;
        height: 217px;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }

  &-slide {
    width: 100%;
    white-space: initial;

    @media (max-width: map-get($grid-breakpoints, md)) {
      box-sizing: content-box;

      &:first-child {
        padding-left: 24px !important;
      }
      &:last-child {
        padding-right: 20px !important;
      }
    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 180px;
    }
  }
}

.link {
  text-decoration: none;
}

::v-deep {
  & .VueCarousel-navigation-next {
    transform: translateY(-100%) translateX(-30%) !important;
  }
  & .VueCarousel-navigation-prev {
    transform: translateY(-100%) translateX(30%) !important;
  }
  & .VueCarousel-wrapper {
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: calc(100% + 24px);
    }
  }
  & .VueCarousel-inner {
    @media (max-width: map-get($grid-breakpoints, md)) {
      margin-right: 100px;
    }
  }
  & .VueCarousel {
    @media (max-width: map-get($grid-breakpoints, md)) {
      margin-right: -12px !important;
      margin-left: -24px !important;
    }
  }
  & #circle1:hover {
    cursor: pointer;
    opacity: 1.5;
  }
}
</style>
